import EventEmitter from "events"
import renderAvatar from "../../atoms/avatar/render"
import { cssColorMXID } from "../../util/colorMXID"
import { selectRoom } from "../action/navigation"
import cons from "./cons"
import navigation from "./navigation"
import settings from "./settings"
import { setFavicon } from "../../util/common"

import LogoSVG from "../../../../../assets/icon.svg"
import LogoUnreadSVG from "../../public/res/svg/cinny-unread.svg"
import LogoHighlightSVG from "../../public/res/svg/cinny-highlight.svg"
import { html, plain } from "../../util/markdown"
import { determineUnreadState } from "../../hooks/useUnreadNotifications"
import {
  MatrixClient,
  NotificationCountType,
  ReceiptType,
  Room
} from "matrix-js-sdk"
import { formatNumber } from "../../../WebPhone2/utils/call"

function isNotifEvent(mEvent) {
  const eType = mEvent.getType()
  if (!cons.supportEventTypes.includes(eType)) return false
  if (eType === "m.room.member") return false

  if (mEvent.isRedacted()) return false
  if (mEvent.getRelation()?.rel_type === "m.replace") return false

  return true
}

function isMutedRule(rule) {
  return (
    rule.actions[0] === "dont_notify" &&
    rule.conditions[0].kind === "event_match"
  )
}

function findMutedRule(overrideRules, roomId) {
  return overrideRules.find(
    (rule) => rule.rule_id === roomId && isMutedRule(rule)
  )
}

const userStatusChannel = new BroadcastChannel("user_status")

class Notifications extends EventEmitter {
  constructor(roomList) {
    super()

    this.initialized = false
    this.favicon = LogoSVG
    this.matrixClient = roomList.matrixClient
    this.roomList = roomList

    this.roomIdToNoti = new Map()
    this.roomIdToPopupNotis = new Map()
    this.eventIdToPopupNoti = new Map()

    this.displayPopupNoti = this._displayPopupNoti
    this.clearRoomNotification = this._clearRoomNotification

    this.currentUserStatus = ""
    userStatusChannel.onmessage = (event) => {
      this.currentUserStatus = event?.data
    }
    // this._initNoti();
    this._listenEvents()

    // Ask for permission by default after loading
    window.Notification?.requestPermission()
  }

  async _initNoti() {
    this.initialized = false
    this.roomIdToNoti = new Map()

    const addNoti = (roomId) => {
      const room = this.matrixClient.getRoom(roomId)
      if (this.getNotiType(room.roomId) === cons.notifs.MUTE) return
      if (this.doesRoomHaveUnread(room) === false) return

      const total = room.getUnreadNotificationCount("total")
      const highlight = room.getUnreadNotificationCount("highlight")
      const { symbol, count, level } = determineUnreadState(
        room,
        undefined,
        false
      )
      this._setNoti(room.roomId, count ?? 0, highlight ?? 0)
    }
      ;[...this.roomList.rooms].forEach(addNoti)
      ;[...this.roomList.directs].forEach(addNoti)
      ;[...this.roomList.favouritesDM].forEach(addNoti)
      ;[...this.roomList.favouritesChannel].forEach(addNoti)

    this.initialized = true
    this._updateFavicon()
  }

  async _clearRoomNotification(room, client) {
    const lastEvent = room.getLastLiveEvent()

    await this.setMarkedUnreadState(room, client, false)

    try {
      if (lastEvent) {
        const receiptType = ReceiptType.Read
        return await client.sendReadReceipt(lastEvent, receiptType, true)
      } else {
        return {}
      }
    } finally {
      // We've had a lot of stuck unread notifications that in e2ee rooms
      // They occur on event decryption when clients try to replicate the logic
      //
      // This resets the notification on a room, even though no read receipt
      // has been sent, particularly useful when the clients has incorrectly
      // notified a user.
      room.setUnreadNotificationCount(NotificationCountType.Highlight, 0)
      room.setUnreadNotificationCount(NotificationCountType.Total, 0)
      for (const thread of room.getThreads()) {
        room.setThreadUnreadNotificationCount(
          thread.id,
          NotificationCountType.Highlight,
          0
        )
        room.setThreadUnreadNotificationCount(
          thread.id,
          NotificationCountType.Total,
          0
        )
      }
    }
  }

  async getMarkedUnreadState(room) {
    const currentStateStable = room
      .getAccountData("m.marked_unread")
      ?.getContent()?.unread
    const currentStateUnstable = room
      .getAccountData("com.famedly.marked_unread")
      ?.getContent()?.unread
    return currentStateStable ?? currentStateUnstable
  }

  async setMarkedUnreadState(room, client, unread) {
    // if there's no event, treat this as false as we don't need to send the flag to clear it if the event isn't there
    const currentState = this.getMarkedUnreadState(room)

    if (Boolean(currentState) !== unread) {
      // Assuming MSC2867 passes FCP with no changes, we should update to start writing
      // the flag to the stable prefix (or both) and then ultimately use only the
      // stable prefix.
      await client.setRoomAccountData(
        room.roomId,
        "com.famedly.marked_unread",
        { unread }
      )
    }
  }

  doesRoomHaveUnread(room) {
    const userId = this.matrixClient.getUserId()
    const readUpToId = room.getEventReadUpTo(userId)
    const liveEvents = room.getLiveTimeline().getEvents()

    if (liveEvents[liveEvents.length - 1]?.getSender() === userId) {
      return false
    }

    for (let i = liveEvents.length - 1; i >= 0; i -= 1) {
      const event = liveEvents[i]
      if (event.getId() === readUpToId) return false
      if (isNotifEvent(event)) return true
    }
    return true
  }

  getNotiType(roomId) {
    const mx = this.matrixClient
    let pushRule
    try {
      pushRule = mx.getRoomPushRule("global", roomId)
    } catch {
      pushRule = undefined
    }

    if (pushRule === undefined) {
      const overrideRules = mx.getAccountData("m.push_rules")?.getContent()
        ?.global?.override
      if (overrideRules === undefined) return cons.notifs.DEFAULT

      const isMuted = findMutedRule(overrideRules, roomId)

      return isMuted ? cons.notifs.MUTE : cons.notifs.DEFAULT
    }
    if (pushRule.actions[0] === "notify") return cons.notifs.ALL_MESSAGES
    return cons.notifs.MENTIONS_AND_KEYWORDS
  }

  getNoti(roomId) {
    return (
      this.roomIdToNoti.get(roomId) || { total: 0, highlight: 0, from: null }
    )
  }

  getTotalNoti(roomId) {
    const { total } = this.getNoti(roomId)
    return total
  }

  getHighlightNoti(roomId) {
    const { highlight } = this.getNoti(roomId)
    return highlight
  }

  getFromNoti(roomId) {
    const { from } = this.getNoti(roomId)
    return from
  }

  hasNoti(roomId) {
    return this.roomIdToNoti.has(roomId)
  }

  deleteNoti(roomId) {
    if (this.hasNoti(roomId)) {
      const noti = this.getNoti(roomId)
      this._deleteNoti(roomId, noti.total, noti.highlight)
    }
  }

  async _updateFavicon() {
    if (!this.initialized) return
    let unread = false
    let highlight = false
      ;[...this.roomIdToNoti.values()].find((noti) => {
        if (!unread) {
          unread = noti.total > 0 || noti.highlight > 0
        }
        highlight = noti.highlight > 0
        if (unread && highlight) return true
        return false
      })
    let newFavicon = LogoSVG
    if (unread && !highlight) {
      newFavicon = LogoUnreadSVG
    }
    if (unread && highlight) {
      newFavicon = LogoHighlightSVG
    }
    if (newFavicon === this.favicon) return
    this.favicon = newFavicon
    setFavicon(this.favicon)
  }

  _setNoti(roomId, total, highlight) {
    const addNoti = (id, t, h, fromId) => {
      const prevTotal = this.roomIdToNoti.get(id)?.total ?? null
      const noti = this.getNoti(id)

      noti.total += t
      noti.highlight += h

      if (fromId) {
        if (noti.from === null) noti.from = new Set()
        noti.from.add(fromId)
      }
      this.roomIdToNoti.set(id, noti)
      this.emit(
        cons.events.notifications.NOTI_CHANGED,
        id,
        noti.total,
        prevTotal
      )
    }

    const noti = this.getNoti(roomId)
    const addT = (highlight > total ? highlight : total) - noti.total
    const addH = highlight - noti.highlight
    if (addT < 0 || addH < 0) return

    addNoti(roomId, addT, addH)
    const allParentSpaces = this.roomList.getAllParentSpaces(roomId)
    allParentSpaces.forEach((spaceId) => {
      addNoti(spaceId, addT, addH, roomId)
    })
    this._updateFavicon()
  }

  _deleteNoti(roomId, total, highlight) {
    const removeNoti = (id, t, h, fromId) => {
      if (this.roomIdToNoti.has(id) === false) return

      const noti = this.getNoti(id)
      const prevTotal = noti.total
      noti.total -= t
      noti.highlight -= h
      if (noti.total < 0) {
        noti.total = 0
        noti.highlight = 0
      }
      if (fromId && noti.from !== null) {
        if (!this.hasNoti(fromId)) noti.from.delete(fromId)
      }
      if (noti.from === null || noti.from.size === 0) {
        if (noti.total == 0) {
          this.roomIdToNoti.delete(id)
        }
        this.emit(cons.events.notifications.FULL_READ, id)
        this.emit(cons.events.notifications.NOTI_CHANGED, id, null, prevTotal)
      } else {
        this.roomIdToNoti.set(id, noti)
        this.emit(
          cons.events.notifications.NOTI_CHANGED,
          id,
          noti.total,
          prevTotal
        )
      }
    }

    removeNoti(roomId, total, highlight)
    const allParentSpaces = this.roomList.getAllParentSpaces(roomId)
    allParentSpaces.forEach((spaceId) => {
      removeNoti(spaceId, total, highlight, roomId)
    })
    this._updateFavicon()
  }

  async _displayPopupNoti(mEvent, room) {
    if (!settings.showNotifications && !settings.isNotificationSounds) return

    const actions = this.matrixClient.getPushActionsForEvent(mEvent)
    const age = mEvent?.getAge()

    if (!actions?.notify || age > 10000) return

    // if ((navigation.selectedRoomId === room.roomId && document.hasFocus()) && window.location.pathname?.includes('chat')) return;
    if (
      navigation.selectedRoomId === room.roomId &&
      window.location.pathname?.includes("chat") &&
      !document.hidden
    )
      return

    if (mEvent.isEncrypted()) {
      await mEvent.attemptDecryption(this.matrixClient.crypto)
    }

    if (settings.showNotifications) {
      let title
      const content = mEvent.getContent()
      const fromNum = content?.from_num
      if (fromNum) {
        title = formatNumber(fromNum)
      } else if (!mEvent.sender || room.name === mEvent.sender.name) {
        title = room.name
      } else if (mEvent.sender) {
        title = `${mEvent.sender.name} (${room.name})`
      }

      const icon = LogoSVG

      const state = { kind: "notification", onlyPlain: true }
      let body
      if (content.format === "org.matrix.custom.html") {
        body = html(content.formatted_body, state)
      } else {
        body = plain(content.body, state)
      }

      const noti = new window.Notification(title, {
        body: body.plain,
        icon,
        tag: mEvent.getId(),
        silent: settings.isNotificationSounds
      })
      if (settings.isNotificationSounds) {
        noti.onshow = () => this._playNotiSound()
      }
      noti.onclick = () => {
        window.focus(import.meta.env.VITE_SITE_URL, "_")
        this.emit("notifications_click", room, mEvent)
      }

      this.eventIdToPopupNoti.set(mEvent.getId(), noti)
      if (this.roomIdToPopupNotis.has(room.roomId)) {
        this.roomIdToPopupNotis.get(room.roomId).push(noti)
      } else {
        this.roomIdToPopupNotis.set(room.roomId, [noti])
      }
    } else {
      this._playNotiSound()
    }
  }

  _deletePopupNoti(eventId) {
    this.eventIdToPopupNoti.get(eventId)?.close()
    this.eventIdToPopupNoti.delete(eventId)
  }

  _deletePopupRoomNotis(roomId) {
    this.roomIdToPopupNotis.get(roomId)?.forEach((n) => {
      this.eventIdToPopupNoti.delete(n.tag)
      n.close()
    })
    this.roomIdToPopupNotis.delete(roomId)
  }

  _playNotiSound() {
    if (!this._notiAudio) {
      this._notiAudio = document.getElementById("notificationSound")
    }
    this._notiAudio.play()
  }

  _playInviteSound() {
    if (!this._inviteAudio) {
      this._inviteAudio = document.getElementById("inviteSound")
    }
    this._inviteAudio.play()
  }

  _listenEvents() {
    this.matrixClient.on(
      "Room.timeline",
      (mEvent, room, toStartOfTimeline, removed, data) => {
        console.log(
          "llllllllllllll",
          removed,
          !data.liveEvent || !!toStartOfTimeline,
          !data.liveEvent,
          !!toStartOfTimeline,
          data.timeline.getTimelineSet().threadListType !== null,
          room.isSpaceRoom(),
          !isNotifEvent(mEvent),
          mEvent.getSender() === this.matrixClient.getUserId(),
          this.getNotiType(room.roomId) === cons.notifs.MUTE,
          mEvent?.getContent(),
          room?.getType() !== "Channels"
        )

        const content = mEvent?.getContent()
        const match = content?.body?.match(/\/([^\/]+)$/)


        if (mEvent.getSender() !== this.matrixClient.getUserId() && content.msgtype == "im.vector.modular.widgets" && match[1]) {
          this.emit("meeting_msg", {
            meeting_id: match[1],
            message: `Meeting is initiated in ${room.name}`,
            room_id: room.roomId
          })
        }

        if (removed) return // only notify for new events, not removed ones
        if (!data.liveEvent || !!toStartOfTimeline) return // only notify for new things, not old.
        if (data.timeline.getTimelineSet().threadListType !== null) return // Ignore events on the thread list generated timelines

        if (mEvent.isRedaction()) this._deletePopupNoti(mEvent.event.redacts)

        if (room.isSpaceRoom()) return
        if (!isNotifEvent(mEvent)) return

        const total = room.getUnreadNotificationCount("total")
        const highlight = room.getUnreadNotificationCount("highlight")
        const { symbol, count, level } = determineUnreadState(
          room,
          undefined,
          false
        )
        if (this.currentUserStatus === "Do not disturb") {
          this._setNoti(room.roomId, count ?? 0, highlight ?? 0)
          return
        }

        // const liveEvents = room.getLiveTimeline().getEvents()

        // const lastTimelineEvent = liveEvents[liveEvents.length - 1]
        // if (lastTimelineEvent.getId() !== mEvent.getId()) return

        if (mEvent.getSender() === this.matrixClient.getUserId()) return

        if (this.getNotiType(room.roomId) === cons.notifs.MUTE) {
          this.deleteNoti(room.roomId, total ?? 0, highlight ?? 0)
          return
        }
        this._setNoti(room.roomId, count ?? 0, highlight ?? 0)
        console.log(this.matrixClient.getSyncState(), "aaaaaaaaaaaaaaaaaaa")
        if (this.matrixClient.getSyncState() === "SYNCING") {
          this._displayPopupNoti(mEvent, room)
        }
      }
    )

    this.matrixClient.on("accountData", (mEvent, oldMEvent) => {
      if (mEvent.getType() === "m.push_rules") {
        const override = mEvent?.getContent()?.global?.override
        const oldOverride = oldMEvent?.getContent()?.global?.override
        if (!override || !oldOverride) return

        const isMuteToggled = (rule, otherOverride) => {
          const roomId = rule.rule_id
          const room = this.matrixClient.getRoom(roomId)
          if (room === null) return false
          if (room.isSpaceRoom()) return false

          const isMuted = isMutedRule(rule)
          if (!isMuted) return false
          const isOtherMuted = findMutedRule(otherOverride, roomId)
          if (isOtherMuted) return false
          return true
        }

        const mutedRules = override.filter((rule) =>
          isMuteToggled(rule, oldOverride)
        )
        const unMutedRules = oldOverride.filter((rule) =>
          isMuteToggled(rule, override)
        )

        mutedRules.forEach((rule) => {
          this.emit(cons.events.notifications.MUTE_TOGGLED, rule.rule_id, true)
          this.deleteNoti(rule.rule_id)
        })
        unMutedRules.forEach((rule) => {
          this.emit(cons.events.notifications.MUTE_TOGGLED, rule.rule_id, false)
          const room = this.matrixClient.getRoom(rule.rule_id)
          if (!this.doesRoomHaveUnread(room)) return
          const total = room.getUnreadNotificationCount("total")
          const highlight = room.getUnreadNotificationCount("highlight")
          const { symbol, count, level } = determineUnreadState(
            room,
            undefined,
            false
          )
          this._setNoti(room.roomId, count ?? 0, highlight ?? 0)
        })
      }
    })

    this.matrixClient.on("Room.receipt", (mEvent, room) => {
      if (mEvent.getType() !== "m.receipt" || room.isSpaceRoom()) return
      const content = mEvent.getContent()
      const userId = this.matrixClient.getUserId()

      Object.keys(content).forEach((eventId) => {
        Object.entries(content[eventId]).forEach(([receiptType, receipt]) => {
          if (!cons.supportReceiptTypes.includes(receiptType)) return
          if (Object.keys(receipt || {}).includes(userId)) {
            this.deleteNoti(room.roomId)
            this._deletePopupRoomNotis(room.roomId)
          }
        })
      })
    })

    this.matrixClient.on("Room.myMembership", (room, membership) => {
      if (membership === "leave" && this.hasNoti(room.roomId)) {
        this.deleteNoti(room.roomId)
      }
      if (membership === "invite") {
        this._playInviteSound()
      }
    })
  }
}

export default Notifications
