import { useRef, useState } from "react"

// export interface Device {
//   label: string
//   value: string
//   deviceInfo: InputDeviceInfo
// }

function updatedMedia() {
  const [isShowVideo, setIsShowVideo] = useState(false)
  const webcamRef = useRef(null)
  const audioStream = useRef(null)

  async function listVideoDevices() {
    const devices = await navigator.mediaDevices?.enumerateDevices()

    const videoInput = []

    devices?.forEach((device) => {
      const deviceInfo: Device = {
        label: device.label,
        value: device.deviceId,
        deviceInfo: device
      }

      switch (device.kind) {
        case "videoinput":
          videoInput.push(deviceInfo)
          break
        default:
          break
      }
    })

    return { videoInput }
  }

  async function listAudioDevices() {
    const devices = await navigator.mediaDevices?.enumerateDevices()

    const audioInput = []
    const audioOutput = []

    devices?.forEach((device) => {
      const deviceInfo: Device = {
        label: device.label,
        value: device.deviceId,
        deviceInfo: device
      }

      switch (device.kind) {
        case "audioinput":
          audioInput.push(deviceInfo)
          break
        case "audiooutput":
          audioOutput.push(deviceInfo)
          break
        default:
          break
      }
    })

    return { audioInput, audioOutput }
  }

  const enableCheckVideo = async () => {
    setIsShowVideo(true)
    localStorage.setItem("video-preview-preference", JSON.stringify(true))
  }

  const disableCheckVideo = () => {
    const { stream } = webcamRef.current
    const tracks = stream.getTracks()
    tracks.forEach((track) => track.stop())
    setIsShowVideo(false)
    localStorage.setItem("video-preview-preference", JSON.stringify(false))
  }

  const enableCheckAudio = async (deviceId?: string) => {
    try {
      audioStream.current = await navigator.mediaDevices.getUserMedia({
        audio: deviceId ? { deviceId } : true
      })
      return audioStream
    } catch (error) {
      return "error"
    }

    // return
  }

  const disableCheckAudio = async () => {
    audioStream.current?.getAudioTracks().forEach((device) => device.stop())
  }

  return {
    enableCheckVideo,
    disableCheckVideo,
    isShowVideo,
    setIsShowVideo,
    webcamRef,
    enableCheckAudio,
    listAudioDevices,
    listVideoDevices,
    audioStream,
    disableCheckAudio
  }
}

export default updatedMedia
